export { ay as default } from './index-c978953d.js';
import 'rxjs';
import 'rxjs/operators';
import 'bowser';
import 'svelte-i18n';
import 'nanoid';
import 'joi';
import '@web3-onboard/common';
import 'bignumber.js';
import 'svelte/store';
import 'lodash.partition';
import 'ethers';
import 'lodash.merge';
import 'eventemitter3';
