import Onboard from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect'
import { ethers } from 'ethers';
import svgIcon from '../images/logo-colors-icon-small.svg';
import svgBanner from '../images/logo-colors-banner-2.svg';

const MAINNET_RPC_URL = 'https://mainnet.infura.io/v3/dd44d48efd2c46238dbd76b877f2394b';
const WALLET_CONNECT_ID = '677bf74fcfbfa248d184344a86b5cc44';

const chains = [
    {
      id: '0x1',
      token: 'ETH',
      label: 'Ethereum Mainnet',
      rpcUrl: MAINNET_RPC_URL,
    },
  ];

const injected = injectedModule();
const wcV2InitOptions = {
  projectId: WALLET_CONNECT_ID,
  requiredChains: [1],
  dappUrl: 'http://musictribes.xyz'
}
const walletConnect = walletConnectModule(wcV2InitOptions)

const wallets = [injected, walletConnect];

const appMetadata = {
    name: 'Music Tribes',
  // SVG icon string, with height or width (whichever is larger) set to 100% or a valid image URL
  // note: if using an emoji make sure to send base64 string
  // Note: `icon` is displayed on both mobile AND desktop. If `logo`
  // below is provided then `icon` displays on mobile and `logo` on desktop
    icon: svgIcon,
  // Optional wide format logo (ie icon and text) to be displayed in the sidebar of connect modal. Defaults to icon if not provided
  // Note: This will ONLY display on desktop. It is best used with wide format logos. Use `icon` for standard 40x40 icons.
    logo: svgBanner,
    description: 'Find your music tribe',
  // if your app only supports injected wallets and when no injected wallets detected, recommend the user to install some
    recommendedInjectedWallets: [
        { name: 'MetaMask', url: 'https://metamask.io' },
        { name: 'Coinbase', url: 'https://wallet.coinbase.com/' }
    ]
}

const onboard = Onboard({
  wallets,
  chains,
  appMetadata
});
console.log(`onboard: ${onboard}`);

var usr_account = '';
export async function connectWallet() {
  const connectedWallets = await onboard.connectWallet();
//  const connectedWallets = await onboard.walletSelect;
  console.log(`wallets: ${connectedWallets}`);
  if (connectedWallets[0]) {
  // create an ethers provider with the last connected wallet provider
//    const ethersProvider = new ethers.BrowserProvider(connectedWallets[0].provider, 'any');

    const ethersProvider = new ethers.providers.Web3Provider(
      connectedWallets[0].provider,
      'any'
    )
    const signer = ethersProvider.getSigner();
    try {
      const res = await signer.getAddress();
      usr_account = res;
      return usr_account;
    } catch (error) {
      // Handle any errors that occurred during the promise chain
      console.error(error);
    }
  }
}
/*
module.exports = {
  connectWallet
};
*/
// https://onboard.blocknative.com/docs/overview/introduction#features